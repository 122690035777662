import React from 'react'
import LogoImg from '../../../assets/images/logo.png'
const Logo = (props) => {

    return (
        <>
            <img src={LogoImg} alt='Logo' width={'80'} />
        </>
    )
}

export default Logo
